<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn v-if="hasRole(['CONTRACT_HOLDER', 'REVIEWER'], 'elkp')" class="ml-3 float-right" depressed @click="$router.push({ name: 'elkp/EmployeeListView' })">
        <VIcon v-text="'mdi-account-search-outline'" />
        Сотрудники
      </VBtn>
      <VBtn depressed @click="toHome">
        <VIcon v-text="'mdi-arrow-left'" />
        На главную
      </VBtn>
    </div>
    <ContractList
      :items="contracts.items"
      :count="contracts.count"
      :page="page"
      :size="size"
      :loading="loading"
    />
    <VFooter v-if="hasRole(['CONTRACT_HOLDER'], 'elkp')" app>
      <div class="py-3 grow">
        <VBtn color="primary" depressed @click="dialog = true">
          Создать договор
        </VBtn>
        <VDialog v-model="dialog" max-width="600">
          <VCard>
            <VCardTitle>Создание договора</VCardTitle>
            <VCardText>
              <ContractCreationForm
                @submit="onCreate"
                @reset="dialog = false"
              />
            </VCardText>
          </VCard>
        </VDialog>
      </div>
    </VFooter>
  </div>
</template>

<script>
import { debounce, get } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import ContractList from '@/components/elkp/ContractList/ContractList';
import ContractCreationForm from '@/components/elkp/ContractCreationForm/ContractCreationForm';
export default {
  name: 'ContractListView',
  components: {
    ContractList,
    ContractCreationForm,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  data: function() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'elkp/pending',
      contracts: 'elkp/contracts',
      hasRole: 'user/hasRole',
    }),
  },
  methods: {
    ...mapActions({
      fetchContractList: 'elkp/fetchContractList',
      deleteContract: 'elkp/deleteContract',
      createContract: 'elkp/createContract',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchContractList({ page, size, filter });
    }, 500),
    onDelete: function(id) {
      this.deleteContract({ id }).then((result) => {
        if (result) this.onUpdateState();
      });
    },
    onCreate({ inn, date, name, number }) {
      this.dialog = false;
      this.createContract({ inn, date, name, number }).then((result) => {
        const contract = get(result, 'id');
        if (contract) this.$router.push({ name: 'elkp/ContractDetailView', params: { contract }});
        else this.onUpdateState();
      });
    },
    toHome() {
      window.location = '/';
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
