var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"value":_vm.selected,"show-select":_vm.showSelect,"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Договор', value: 'number', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Сотрудники', value: 'employees', sortable: false },
        { text: _vm.isReviewer ? 'Исправленные замечания' : 'Неисправленные замечания', value: 'comments', sortable: false },
      ],"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":({ comments }) => !!comments ? (_vm.isReviewer ? 'orange lighten-5': 'error lighten-4'): null},on:{"update:options":function($event){_vm.options=$event},"input":(items) => _vm.$emit('update:selected', items),"click:row":({ id }) => _vm.$router.push({ name: 'elkp/ContractDetailView', params: { contract: id }}),"update:page":page => _vm.$router.push({ query: { ..._vm.$route.query, page } }),"update:items-per-page":size => _vm.$router.push({ query: { ..._vm.$route.query, size } })},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_vm._l((['id', 'inn', 'number', 'name']),function(key){return _c('td',{key:key},[_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[key]},on:{"input":value => _vm.$router.push({ query: { ..._vm.$route.query, [key]: value } })}})],1)}),_c('td',{attrs:{"colspan":"2"}})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }