<template>
  <div :class="$style.root">
    <VForm ref="form" v-model="valid" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <VRow>
        <VCol cols="12" md="6">
          <VTextField
              label="Номер договора"
              v-model="fields.number"
              :rules="[(val) => !!val || 'Обязательное поле']"
              dense outlined hide-details="auto"
          />
        </VCol>
        <VCol cols="12" md="6">
          <DateInput
              label="Дата окончание действия"
              type="DATE"
              :value="fields['date']"
              :rules="[!!fields['date'] || 'Обязательное поле']"
              @input="value => fields['date'] = value"
          />
        </VCol>
        <VCol cols="12" md="6">
          <template v-if="!hasRole('CONTRACT_HOLDER', 'elkp')">
            <StaticField label="Организация" :value="fields.inn" dense outlined hide-details="auto" />
          </template>
          <template v-else>
            <VTextField
                label="ИНН контрагента"
                v-model="fields.inn"
                :rules="[
                  (val) => !!val || 'Обязательное поле',
                  (val) => ({10: true, 12: true}[val.length] || 'ИНН должен содержать 10 или 12 знаков'),
                  () => !!fields.name || 'Пользователь не найден',
                ]"
                dense
                outlined
                hide-details="auto"
            />
          </template>
        </VCol>
        <VCol cols="12" md="6">
          <StaticField label="Организация" :value="fields.name" dense outlined hide-details="auto" />
        </VCol>
        <VCol cols="12">
          <VBtn color="primary" type="submit" :disabled="!valid || !fields['name'] || pending" depressed>
            Сохранить
          </VBtn>
          <VBtn depressed class="ml-4" @click="onReset">
            Отмена
          </VBtn>
        </VCol>
      </VRow>
    </VForm>
  </div>
</template>

<script>
import { get, debounce } from 'lodash-es';
import { mapGetters } from 'vuex';
import api from '@/store/user/api';
import DateInput from '@/components/general/DateInput/DateInput';
import StaticField from '@/components/general/StaticField/StaticField';
export default {
  name: 'ContractCreationForm',
  components: {
    DateInput,
    StaticField,
  },
  props: {
    values: {
      type: Object,
      default: () => ({
        number: '',
        date: '',
        inn: '',
        name: '',
      }),
    },
  },
  data: function() {
    return {
      pending: false,
      valid: false,
      fields: JSON.parse(JSON.stringify(this.values)),
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
    }),
    inn() {
      return get(this.fields, 'inn', '');
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
      this.$emit('reset');
    },
    onSubmit: function() {
      this.$emit('submit', this.fields);
      this.fields['date'] = '';
      this.fields['number'] = '';
    },
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler() {
        if (this.hasRole('CONTRAGENT', 'elkp')) {
          this.fields['name'] = get(this.user, 'company.name', '');
          this.fields['inn'] = get(this.user, 'company.inn', '');
        }
      }
    },
    inn: debounce(function(inn) {
      this.fields['name'] = '';
      if (inn.length === 10 || inn.length === 12) {
        this.pending = true;
        api
        .item({ inn })
        .then((user) => {
          this.fields['name'] = get(user, 'company.name', '');
          this.pending = false;
          this.$refs.form.validate();
        })
        .catch(() => {
          this.valid = false;
          this.fields['name'] = '';
          this.pending = false;
          this.$refs.form.validate();
        });
      }
    }, 500)
  }
}
</script>

<style module lang="scss">
.root {}
</style>
